//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        data: {
            type: Array,
            default: () => ([]),
        },
    },

    computed: {
        getData() {
            return this.data.filter((object) => object.text || object.links);
        },
    },
};
