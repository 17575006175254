var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.posts.posts.length)?_c('div',{staticClass:"cards-posts"},[_vm._l((_vm.posts.posts),function(post,index){return [_c('base-item-post',{key:("post-" + index),attrs:{"img":post.featuredMedia,"img-loading":index === 0 ? 'eager' : 'lazy',"img-info":[{
                icon: 'comment',
                text: post.commentsNumber
            }],"title":post.title,"slug":post.slug,"body-info":[{
                icon: 'date',
                text: _vm.$options.filters.formatDate(post.date)
            }, {
                icon: 'folder',
                links: post.categories
            }],"body-text":post.excerpt}})]}),_vm._v(" "),(_vm.posts.pagination)?_c('nav',{attrs:{"aria-label":"Page navigation"}},[_c('ul',{staticClass:"pagination"},[_vm._l((_vm.posts.pagination.pages),function(page,index){return [(page !== '...')?_c('li',{key:'pagination-item-' + index,staticClass:"page-item"},[_c('router-link',{staticClass:"page-link",class:{ active: [_vm.posts.pagination.currentPage] == page },attrs:{"to":_vm.paginationTo(page),"active-class":"","exact-active-class":""}},[_vm._v("\n                        "+_vm._s(page)+"\n                    ")])],1):_c('li',{key:'pagination-item-' + index,staticClass:"page-item"},[_vm._v("\n                    "+_vm._s(page)+"\n                ")])]})],2)]):_vm._e()],2):_c('div',[_vm._v("\n    Niciun articol aici.\n")])}
var staticRenderFns = []

export { render, staticRenderFns }