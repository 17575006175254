//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ListItemInfo from '~/components/ListItemInfo.vue';

export default {
    components: {
        ListItemInfo,
    },

    props: {
        img: {
            type: [Boolean, Object],
            default: false,
        },
        imgLoading: {
            type: String,
            default: 'lazy',
        },
        imgInfo: {
            type: Array,
            default: () => ([]),
        },
        title: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: true,
        },
        bodyInfo: {
            type: Array,
            default: () => ([]),
        },
        bodyText: {
            type: String,
            default: '',
        },
    },

    methods: {
        postTo(slug) {
            return {
                name: 'Single',
                params: {
                    singleSlug: slug,
                    singleType: 'post',
                },
            };
        },
    },
};
