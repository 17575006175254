//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
    },
};
