import { render, staticRenderFns } from "./PostsList.vue?vue&type=template&id=afbc3184&"
import script from "./PostsList.vue?vue&type=script&lang=js&"
export * from "./PostsList.vue?vue&type=script&lang=js&"
import style0 from "./PostsList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseItemPost: require('/home/runner/work/cabral.ro/cabral.ro/components/BaseItemPost.vue').default})
